<template>
  <section class="slide">
    <div>
      <router-link to="catalogue">
        <div class="card">
          <div class="card_part card_part-one"></div>
          <!-- Photo 2 -->
          <div class="card_part card_part-two"></div>
          <!-- Photo 3 -->
          <div class="card_part card_part-three"></div>
          <!-- Photo 4 -->
          <div class="card_part card_part-four"></div>
          <!-- Photo 5 -->
          <div class="card_part card_part-five"></div>
          <!-- Photo 6 -->
          <div class="card_part card_part-six"></div>
          <!-- Photo 7 -->
          <div class="card_part card_part-seven"></div>
          <!-- Photo 8 -->
          <div class="card_part card_part-eight"></div></div
      ></router-link>
    </div>
    <div class="dextro-logo"></div>
  </section>
</template>

<script>
export default {
  name: "SlideComponent",
};
</script>

<style scoped>
.slide {
  background-color: #d3d2c5;
  height: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide div {
  display: none;
}
.card {
  position: relative;
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  background-color: #cecebf;
  margin: auto;
}
.card_part {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: translateX(700px);
  background-image: url(../assets/slides/POM42.png);

  animation: opaqTransition 56s cubic-bezier(0, 0, 0, 0.97) infinite;
}
.card_part.card_part-two {
  z-index: 8;
  background-image: url(../assets/slides/pom43.png);
  animation-delay: 7s;
}
.card_part.card_part-three {
  z-index: 7;
  background-image: url(../assets/slides/pom44.png);
  animation-delay: 14s;
}
.card_part.card_part-four {
  z-index: 6;
  background-image: url(../assets/slides/pom45.png);
  animation-delay: 21s;
}
.card_part.card_part-five {
  z-index: 5;
  background-image: url(../assets/slides/pom46.png);
  animation-delay: 28s;
}
.card_part.card_part-six {
  z-index: 4;
  background-image: url(../assets/slides/pom47.png);
  animation-delay: 35s;
}
.card_part.card_part-seven {
  z-index: 3;
  background-image: url(../assets/slides/pom48.png);
  animation-delay: 42s;
}
.card_part.card_part-eight {
  z-index: 2;
  background-image: url(../assets/slides/pom49.png);
  animation-delay: 49s;
}

@keyframes opaqTransition {
  3% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  28% {
    transform: translateX(-700px);
  }
  100% {
    transform: translateX(-700px);
  }
}
@media screen and (min-width: 450px) {
  .slide {
    height: 660px;
  }
  .slide div {
    display: block;
  }
}
</style>
